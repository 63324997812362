import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import HubspotForm from 'react-hubspot-form'
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            caseStudy(id: $id) {
                title
                uri
                content
                coverImage
                excerpt
                industry
                companySize
                companyType
                foundedYear
                publishedDate
                headquarter
                hsFormId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const CaseStudyTemplate = ({ data }) => {
    const casestudy = data.wpcontent.caseStudy

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const metaDesc = data.wpcontent.caseStudy.seo.metaDesc
    const pageTitle = data.wpcontent.caseStudy.seo.title
    // const pageType = data.wpcontent.caseStudy.seo.opengraphType

    // let pageurl = `https://www.vantagecircle.com`
    // const uri = casestudy.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }
    return (

        <Layout className="webinar-detail">
            <Seo title={pageTitle}
                description={metaDesc}
                // meta={[
                //   {
                //     name: `description`,
                //     content: metaDesc,
                //   },
                //   {
                //     property: `og:title`,
                //     content: pageTitle,
                //   },
                //   {
                //     property: `og:description`,
                //     content: metaDesc,
                //   },
                //   {
                //     property: `og:type`,
                //     content: pageType,
                //   },
                //   {
                //     name: `twitter:title`,
                //     content: pageTitle,
                //   },
                //   {
                //     name: `twitter:description`,
                //     content: metaDesc,
                //   },
                // ]}
                // link={[
				// 	{
				// 		rel: `canonical`,
				// 		href: pageurl,
				// 	},
				// ]}
            />
            <section className="bg-purple-50 mx-auto py-10 pb-5 sm:py-20 sm:pb-20 relative">
                <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
                    <div className="site-breadcrumb sm:text-center mb-5 text-gray-200">
                        <Link className="hover:underline" to="/">Home</Link>&nbsp;>>&nbsp;
                        <Link className="hover:underline" to="/case-study/">Success Stories</Link>
                        {/* <span className="" dangerouslySetInnerHTML={{ __html: casestudy.title }} /> */}
                    </div>
                    <h1 className="homepage-heading font-bold text-gray-500" dangerouslySetInnerHTML={{ __html: casestudy.title }} />
                    {/* <h3 className={casestudy.subTitle != 'null' ? "homepage-div-heading my-3 text-gray-500 line-clamp-3" : "hidden"}
                      dangerouslySetInnerHTML={{ __html: casestudy.subTitle }}
                    /> */}
                    <p className="section-subtitle" dangerouslySetInnerHTML={{ __html: casestudy.excerpt }} />
                </div>
                <img className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/Webinar-Hero-L1.png" alt="Success Stories" />
                <img className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/Webinar-Hero-L2.png" alt="Success Stories" />
                <img className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/SuccessStory-Hero-R1.png" alt="Success Stories" />
                <img className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/SuccessStory-Hero-R2.png" alt="Success Stories" />
            </section>
            <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div>
            <section className="container max-w-7xl px-6 lg:px-8 pb-20 md:-mt-36">
                <div>
                    <div className="bg-white block md:flex card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="w-full md:w-3/5">
                            <img className="p-5" src={casestudy.coverImage} alt={casestudy.title} />
                        </div>
                        <div className="p-5 md:p-8 w-full md:w-2/5">
                            <h2 className="mt-2 mb-5 md:mb-10 text-2xl font-semibold text-gray-900 sm:text-3xl">About the Organization</h2>
                            <ul className="text-base sm:text-sm md:text-lg text-gray-500 list-inside">
                                { casestudy.industry!='null' ? <li className="flex mb-3 sm:mb-5"><span className="w-2/5 font-bold"> Industry: </span> <span className="org-details flex-1">{casestudy.industry}</span> </li> : ''}
                                { casestudy.companySize!='null' ? <li className="flex mb-3 sm:mb-5"><span className="w-2/5 font-bold"> Company Size: </span> <span className="org-details flex-1">{casestudy.companySize}</span> </li> : ''}
                                { casestudy.headquarter!='null' ? <li className="flex mb-3 sm:mb-5"><span className="w-2/5 font-bold"> Headquarters: </span> <span className="org-details flex-1">{casestudy.headquarter}</span> </li> : ''}
                                { casestudy.companyType!='null' ? <li className="flex mb-3 sm:mb-5"><span className="w-2/5 font-bold"> Type: </span> <span className="org-details flex-1">{casestudy.companyType}</span> </li> : ''}
                                { casestudy.foundedYear!='null' ? <li className="flex"><span className="w-2/5 font-bold"> Founded: </span> <span className="org-details flex-1">{casestudy.foundedYear}</span> </li> : '' } 
                            </ul>
                        </div>
                    </div>
                </div>
            </section> 
            <div dangerouslySetInnerHTML={{ __html: casestudy.content }} />
            <section className="container max-w-4xl px-4 sm:px-6 lg:px-8 pb-5 md:pb-0">
                <div className="flex justify-center">
                    <div className="">
                        <button onClick={onOpenModal} className="vc-colored-btn">
                            Read more
                        </button>
                    </div>
                </div>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className="form-section">
                        <h2 className="form-title mt-2 mb-10 text-2xl font-semibold text-gray-900 sm:text-3xl pb-2">Let us know your details</h2>
                        <HubspotForm
                            portalId='6153052'
                            formId={casestudy.hsFormId}
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        />
                        {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div> */}
                    </div>
                </Modal>
            </section>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden my-20">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default CaseStudyTemplate

